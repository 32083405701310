html {
  scroll-behavior: smooth !important;
}
.formError .formErrorContent,
.formError .formErrorArrow {
  display: none !important;
}
.errorLogin .error {
  color: #F2385A;
  font-weight: 500;
  background-color: #fff;
  border: 1px solid #F2385A;
  padding: 5px 20px;
  margin: 0 30px 10px 0 !important;
}
.section0text {
  font-size: 40px;
}
body {
  font: 13px/1.5 'Nunito', sans-serif !important;
}
.row.spaceRL {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 75% !important;
}
@media screen and (max-width: 1100px) {
  .row.spaceRL {
    width: 95% !important;
  }
}
.row.spaceRL .feature {
  font-family: Arial;
  position: relative;
  border: 1px solid #F5A34C;
  border-radius: 2%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  padding: 40px;
  margin: 10px;
  flex-basis: 30%;
  box-sizing: border-box;
  color: #454545 !important;
}
@media screen and (max-width: 910px) {
  .row.spaceRL .feature {
    flex-basis: 46%;
  }
}
@media screen and (max-width: 556px) {
  .row.spaceRL .feature {
    flex-basis: 90%;
  }
}
.row.spaceRL .feature img {
  height: 100px;
  padding: 20px;
}
.row.spaceRL .feature p {
  font-weight: 600;
  font-size: 17px;
  letter-spacing: 1px;
  padding-bottom: 20px;
  text-align: center;
}
.row.spaceRL .feature .text {
  color: #a3a4a5;
  text-align: center;
  line-height: 1.7;
}
.row.spaceRL .feature .small-dot {
  position: absolute;
  height: 10px;
  width: 10px;
  background-color: #f07c00;
  border-radius: 50%;
  bottom: -5px;
  left: calc(50%);
}
.hero-image {
  position: relative;
  height: 460px;
  width: 100%;
  margin-bottom: -80px;
}
.hero-image .image {
  height: 480px;
  background-attachment: fixed;
}
@media screen and (max-width: 627px) {
  .hero-image .image {
    height: 576px;
  }
}
.hero-image .contact-box {
  padding: 33px;
  position: absolute;
  top: 60px;
  right: 272px;
  line-height: 1;
  background: rgba(255, 255, 255, 0.2);
  border: 2px solid rgba(255, 255, 255, 0.2);
  border-radius: 8px;
}
@media screen and (max-width: 1300px) {
  .hero-image .contact-box {
    top: 27px;
    right: 70px;
  }
}
@media screen and (max-width: 870px) {
  .hero-image .contact-box {
    top: 20px;
    right: 20px;
  }
}
@media screen and (max-width: 660px) {
  .hero-image .contact-box {
    padding: 20px;
  }
}
@media screen and (max-width: 560px) {
  .hero-image .contact-box {
    top: 200px;
    left: 18%;
    right: auto;
  }
  .hero-image .contact-box .tt-text {
    padding: 1px 0 10px 0;
  }
  .hero-image .contact-box .address {
    margin-bottom: 19px !important;
  }
}
.hero-image .contact-box p {
  font-size: 25px !important;
  letter-spacing: 1px;
  line-height: 0.8;
  text-shadow: none;
}
.hero-image .contact-box .address {
  margin-bottom: 33px;
}
.hero-image .contact-box .address p {
  line-height: 0.1;
  padding-top: 10px;
  font-size: 12px !important;
  font-weight: 400 !important;
}
.hero-image .contact-box .contact-field {
  position: relative;
  width: 189px;
  font-family: Nunito;
  margin-bottom: 10px !important;
  font-size: 13px;
  font-weight: 400;
  letter-spacing: 0em;
  text-align: left;
  height: 40px;
  line-height: 40px;
  background-color: #fff;
  border-radius: 8px;
  color: #696969;
  box-sizing: border-box;
  padding-left: 11px;
}
.hero-image .contact-box .contact-field .icon-wrapper {
  border-right: 1px solid #E8E8E8;
  width: 40px;
  padding: 8px 8px 8px 0px;
}
.hero-image .contact-box .contact-field .icon-wrapper img {
  width: 20px;
  height: 20px;
}
.hero-image .contact-box.tg {
  padding: 40px 20px 40px 20px;
  top: 50px;
  right: 150px;
  background-color: white;
}
@media screen and (max-width: 560px) {
  .hero-image .contact-box.tg {
    top: 320px;
    left: 18%;
    right: auto;
  }
  .hero-image .contact-box.tg .tt-text {
    padding: 1px 0 10px 0;
  }
  .hero-image .contact-box.tg .address {
    margin-bottom: 19px !important;
  }
}
.hero-image img {
  width: 100%;
}
.hero-image .hero-text {
  position: absolute !important;
  top: 140px;
  left: 13%;
  line-height: 1.1;
}
@media screen and (max-width: 870px) {
  .hero-image .hero-text {
    top: 120px;
    right: 0;
    left: 30px;
  }
}
@media screen and (max-width: 760px) {
  .hero-image .hero-text {
    left: 25px;
  }
  .hero-image .hero-text p {
    font-size: 40px !important;
  }
}
@media screen and (max-width: 660px) {
  .hero-image .hero-text {
    top: 140px;
  }
  .hero-image .hero-text p {
    font-size: 35px !important;
  }
}
@media screen and (max-width: 560px) {
  .hero-image .hero-text {
    top: 40px;
    max-width: 255px;
    left: 19%;
  }
  .hero-image .hero-text p {
    font-size: 30px !important;
  }
}
.hero-image .hero-text p:last-of-type {
  color: #f07c00;
}
.hero-image .hero-text p:last-of-type span {
  color: #fff;
}
.promo.image-text {
  position: relative;
  display: flex !important;
  justify-content: center;
  background-color: #213B54;
  width: 100%;
  height: 200px;
  margin-top: 80px;
  margin-bottom: 90px;
}
@media screen and (max-width: 627px) {
  .promo.image-text {
    margin-top: 196px;
  }
}
.promo.image-text .promo-text {
  max-width: 1043px;
  position: absolute;
  bottom: 50px;
  font-weight: 200;
  color: #fff;
  font-size: 20px;
  line-height: 1.2;
}
@media screen and (max-width: 600px) {
  .promo.image-text .promo-text {
    font-size: 17px;
    padding: 0 10px;
    bottom: 34px;
  }
}
@media screen and (max-width: 380px) {
  .promo.image-text .promo-text {
    font-size: 16px;
    padding: 0 10px;
    bottom: 30px;
  }
}
.promo.image-text .promo-text span:first-child,
.promo.image-text .promo-text span:last-child {
  color: #fff;
  font-weight: 500;
}
.promo.image-text .vector {
  position: absolute;
  bottom: -60px;
  left: 50%;
  transform: translate(-50%, -50%);
}
@media screen and (max-width: 380px) {
  .promo.image-text .vector {
    max-width: 360px;
    bottom: -57px;
  }
}
.promo.image-text a {
  position: absolute;
  bottom: -10px;
  left: 50%;
  transform: translate(-50%, -50%);
}
.promo.image-text.tg {
  background-color: #06508D;
}
@media screen and (max-width: 627px) {
  .promo.image-text.tg {
    margin-top: 275px;
  }
}
.loginBoxInput {
  border: solid #ddd 1px !important;
  border-radius: 5%;
}
input.loginBoxInput:focus {
  border: 1px solid #F07C00 !important;
}
.forgot_password {
  color: #f07d00 !important;
  font-size: 11px;
  text-decoration: underline;
}
#header {
  height: 87px;
}
@media screen and (max-width: 720px) {
  #header {
    height: 50px;
  }
}
#header .border_box {
  border-bottom: 1px solid #a9aaab !important;
}
#section0 {
  height: auto !important;
}
.section-div .option {
  border: 1px solid #F5A34C !important;
}
#contact label {
  color: #fff;
}
#footer {
  margin-top: 20px;
  width: 100%;
  height: 90px;
  background-color: #F9F9F9;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  justify-content: space-around;
}
@media screen and (max-width: 866px) {
  #footer {
    padding: 20px 30px 20px 20px;
    flex-direction: column;
    height: auto;
  }
  #footer span:first-of-type {
    text-align: center;
  }
}
#footer .grid-12.copyr {
  width: 90% !important;
}
#footer span,
#footer a {
  color: #454545 !important;
  font-size: 10px;
}
#footer .footer-icons {
  display: flex;
  justify-content: flex-end;
  margin-left: 162px;
}
#footer .footer-icons img {
  padding-left: 20px;
}
#footer .ir-in {
  background-size: contain;
  margin-top: 12px;
}
.contact-field .icon-text {
  padding-left: 10px;
}
.contact-field a {
  text-decoration: none !important;
  color: #696969 !important;
}
#subheader.text-center.subheader,
#subheader2.text-center.subheader {
  visibility: visible !important;
  position: absolute;
  background-color: transparent !important;
  bottom: 130px;
  color: #fff !important;
  font-weight: bold;
  font-size: 30px;
  line-height: 120%;
}
@media screen and (max-width: 600px) {
  #subheader.text-center.subheader,
  #subheader2.text-center.subheader {
    font-size: 25px;
    padding: 0 10px;
  }
}
#subheader.text-center.subheader span:last-child,
#subheader2.text-center.subheader span:last-child {
  color: #F07C00;
}
#subheader.text-center.subheader .arrow_div .arrow,
#subheader2.text-center.subheader .arrow_div .arrow {
  position: absolute;
  bottom: 100px;
  left: 10%;
  z-index: 200;
  display: none !important;
}
@media screen and (max-width: 600px) {
  #subheader.text-center.subheader .arrow_div .arrow,
  #subheader2.text-center.subheader .arrow_div .arrow {
    bottom: 50px;
  }
}
#subheader2.text-center.subheader {
  bottom: 80px;
}
.tt-text,
.et-text {
  color: #F07C00 !important;
  font-size: 16px;
  font-weight: 800;
  letter-spacing: 1px;
  text-shadow: none;
  padding: 20px 0 10px 0;
}
.et-text {
  color: #fff !important;
}
.padd-bottom {
  padding-bottom: 50px;
}
.form-check-login.new {
  width: 500px;
  padding-top: 10px;
}
@media screen and (max-device-width: 600px) {
  .form-check-login.new {
    width: 208px;
  }
}
.form-check-login.new #user-name,
.form-check-login.new #user-pass {
  background-color: #fff !important;
  font-family: Nunito;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  height: 40px !important;
  line-height: 40px !important;
  color: #1f1f1f !important;
  padding: 9px 20px;
}
label.user-name,
label.user-pass {
  position: absolute;
  top: -12px;
  left: 10px;
  z-index: 10;
  background-color: #fff;
  padding: 0 5px;
  color: #9C9E9F;
}
label.user-pass {
  left: 189px;
}
.loginButton .submit-form.btn2 {
  height: 40px !important;
  line-height: 40px !important;
  border-radius: 5% !important;
}
.loginButton .submit-form.btn2 span {
  font-family: Nunito;
  font-style: normal;
  font-weight: 600 !important;
  font-size: 14px;
}
.loginBox:nth-child(2) {
  display: flex;
}
@media screen and (max-width: 720px) {
  .loginBox:nth-child(2) {
    display: none;
  }
}
@media screen and (max-width: 720px) {
  .loginBox:nth-child(2).mobile {
    display: inline-block !important;
    position: absolute;
    top: 40px;
    right: 40px;
    background-color: #fff;
    width: 250px;
    height: 216px;
    border: 1px solid #ddd;
    border-radius: 3%;
    top: 49px;
    padding: 20px;
  }
  .loginBox:nth-child(2).mobile dt label.user-pass {
    top: 42px;
    left: 10px;
  }
  .loginBox:nth-child(2).mobile .loginButton {
    padding-left: 0px;
  }
  .loginBox:nth-child(2).mobile .loginButton .submit-form.btn2 {
    width: 205px;
    text-align: center;
  }
  .loginBox:nth-child(2).mobile .form-layout-2 .req:nth-of-type(2) {
    display: flex;
    flex-direction: column;
  }
  .loginBox:nth-child(2).mobile .form-layout-2 .req:nth-of-type(2) input {
    width: 205px;
    height: 20px;
    margin-bottom: 15px;
  }
}
@media screen and (max-width: 380px) {
  .loginBox:nth-child(2).mobile {
    right: 20px;
  }
}
.mobile-login-box {
  display: none;
}
@media screen and (max-width: 720px) {
  .mobile-login-box {
    box-sizing: border-box;
    font-family: "Nunito", sans-serif;
    display: inline-block !important;
    height: 40px;
    padding: 10px 0px 10px 25px;
    color: #424242;
    border: 1px solid #ddd;
    border-radius: 5%;
    position: absolute;
    top: 5px;
    right: 40px;
  }
  .mobile-login-box img {
    padding-right: 9px;
    margin-top: -5px;
  }
  .mobile-login-box span:first-of-type {
    padding-right: 20px;
  }
  .mobile-login-box .arrow {
    border-left: 1px solid #ddd;
    padding: 10px 5px 10px 12px;
    transform: rotate(180deg);
  }
}
@media screen and (max-width: 380px) {
  .mobile-login-box {
    right: 21px;
  }
}
@media screen and (max-width: 720px) {
  .logo img {
    width: 120px;
    height: 30px;
    padding-left: -20px;
    margin-left: -20px;
  }
}
@media screen and (max-device-width: 600px) {
  .login_header_flex fieldset.form-login {
    min-width: 208px !important;
  }
}
