html {
	scroll-behavior: smooth !important;
}

.formError .formErrorContent, .formError .formErrorArrow {
	display: none !important;
}

.errorLogin .error {
    color: #F2385A;
    font-weight: 500;
    background-color: #fff;
    border: 1px solid #F2385A;
    padding: 5px 20px;
    margin: 0 30px 10px 0 !important;
}
.section0text {
	font-size: 40px;
}

body {
	font: 13px/1.5 'Nunito', sans-serif !important;
}

.row.spaceRL {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	width: 75% !important;
	@media screen and (max-width: 1100px) {
		width: 95% !important;
	}

	.feature {
		font-family: Arial;
		position: relative;
		border: 1px solid #F5A34C;
		border-radius: 2%;
		display: flex;
		flex-direction: column;
		flex-wrap: wrap;
		justify-content: flex-start;
		align-items: center;
		padding: 40px;
		margin: 10px;
		flex-basis: 30%;
		box-sizing: border-box;
		color: #454545 !important;
		@media screen and (max-width: 910px) {
			flex-basis: 46%;
		}
		@media screen and (max-width: 556px) {
			flex-basis: 90%;
		}

		img {
			height: 100px;
			padding: 20px;
		}

		p {
			font-weight: 600;
			font-size: 17px;
			letter-spacing: 1px;
			padding-bottom: 20px;
			text-align: center;
		}

		.text {
			color: #a3a4a5;
			text-align: center;
			line-height: 1.7;
		}

		.small-dot {
			position: absolute;
			height: 10px;
			width: 10px;
			background-color: #f07c00;
			border-radius: 50%;
			bottom: -5px;
			left: calc(50%);
		}
	}
}

.hero-image {
	position: relative;
	height: 460px;
	width: 100%;
	margin-bottom: -80px;

	.image {
		height: 480px;
		background-attachment: fixed;
		@media screen and (max-width: 627px) {
			height: 576px;
		}
	}

	.contact-box {
		padding: 33px;
		position: absolute;
		top: 60px;
		right: 272px;
		line-height: 1;
		background: rgba(255, 255, 255, 0.2);
		border: 2px solid rgba(255, 255, 255, 0.2);
		border-radius: 8px;

		@media screen and (max-width: 1300px) {
			top: 27px;
			right: 70px;
		}
		@media screen and (max-width: 870px) {
			top: 20px;
			right: 20px;
		}
		@media screen and (max-width: 660px) {
			padding: 20px;
		}
		@media screen and (max-width: 560px) {
			top: 200px;
			left: 18%;
			right: auto;
			.tt-text {
				padding: 1px 0 10px 0;
			}
			.address {
				margin-bottom: 19px !important;
			}
		}

		p {
			font-size: 25px !important;
			letter-spacing: 1px;
			line-height: 0.8;
			text-shadow: none;
		}

		.address {
			margin-bottom: 33px;

			p {
				line-height: 0.1;
				padding-top: 10px;
				font-size: 12px !important;
				font-weight: 400 !important;
			}
		}

		.contact-field {
			position: relative;
			width: 189px;
			font-family: Nunito;
			margin-bottom: 10px !important;
			font-size: 13px;
			font-weight: 400;
			letter-spacing: 0em;
			text-align: left;
			height: 40px;
			line-height: 40px;
			background-color: #fff;
			border-radius: 8px;
			color: #696969;
			box-sizing: border-box;
			padding-left: 11px;

			.icon-wrapper {
				border-right: 1px solid #E8E8E8;
				width: 40px;
				padding: 8px 8px 8px 0px;

				img {
					width: 20px;
					height: 20px;
				}
			}
		}
	}

	.contact-box.tg {
		padding: 40px 20px 40px 20px;
		top: 50px;
		right: 150px;
		background-color: white;

		@media screen and (max-width: 560px) {
			top: 320px;
			left: 18%;
			right: auto;
			.tt-text {
				padding: 1px 0 10px 0;
			}
			.address {
				margin-bottom: 19px !important;
			}
		}
	}

	img {
		width: 100%;
	}

	.hero-text {
		position: absolute !important;
		top: 140px;
		left: 13%;
		line-height: 1.1;

		@media screen and (max-width: 870px) {
			top: 120px;
			right: 0;
			left: 30px;
		}
		@media screen and (max-width: 760px) {
			left: 25px;
			p {
				font-size: 40px !important;
			}
		}
		@media screen and (max-width: 660px) {
			top: 140px;
			p {
				font-size: 35px !important;
			}
		}
		@media screen and (max-width: 560px) {
			top: 40px;
			max-width: 255px;
			left: 19%;
			p {
				font-size: 30px !important;
			}
		}
		p:last-of-type {
			color: #f07c00;

			span {
				color: #fff;
			}
		}
	}
}

.promo.image-text {
	position: relative;
	display: flex !important;
	justify-content: center;
	background-color: #213B54;
	width: 100%;
	height: 200px;
	margin-top: 80px;
	margin-bottom: 90px;
	@media screen and (max-width: 627px) {
		margin-top: 196px;
	}

	.promo-text {
		max-width: 1043px;
		position: absolute;
		bottom: 50px;
		font-weight: 200;
		color: #fff;
		font-size: 20px;
		line-height: 1.2;
		@media screen and (max-width: 600px) {
			font-size: 17px;
			padding: 0 10px;
			bottom: 34px;
		}
		@media screen and (max-width: 380px) {
			font-size: 16px;
			padding: 0 10px;
			bottom: 30px;
		}

		span:first-child,
		span:last-child {
			color: #fff;
			font-weight: 500;
		}
	}

	.vector {
		position: absolute;
		bottom: -60px;
		left: 50%;
		transform: translate(-50%, -50%);
		@media screen and (max-width: 380px) {
			max-width: 360px;
			bottom: -57px;
		}
	}

	a {
		position: absolute;
		bottom: -10px;
		left: 50%;
		transform: translate(-50%, -50%);
	}
}

.promo.image-text.tg {
	background-color: #06508D;
	@media screen and (max-width: 627px) {
		margin-top: 275px;
	}
}

.loginBoxInput {
	border: solid #ddd 1px !important;
	border-radius: 5%;
}

input.loginBoxInput:focus {
	border: 1px solid #F07C00 !important;
}

.forgot_password {
	color: #f07d00 !important;
	font-size: 11px;
	text-decoration: underline;
}

#header {
	height: 87px;
	@media screen and (max-width: 720px) {
		height: 50px;
	}

	.border_box {
		border-bottom: 1px solid #a9aaab !important;
	}
}

#section0 {
	height: auto !important;
}

.section-div {
	.option {
		border: 1px solid #F5A34C !important;
	}
}

#contact {
	label {
		color: #fff;
	}
}

#footer {
	margin-top: 20px;
	width: 100%;
	height: 90px;
	background-color: #F9F9F9;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: row;
	justify-content: space-around;
	@media screen and (max-width: 866px) {
		padding: 20px 30px 20px 20px;
		flex-direction: column;
		height: auto;
		& span:first-of-type {
			text-align: center;
		}
	}

	.grid-12.copyr {
		width: 90% !important;
	}

	span, a {
		color: #454545 !important;
		font-size: 10px;
	}

	.footer-icons {
		display: flex;
		justify-content: flex-end;
		margin-left: 162px;

		img {
			padding-left: 20px;
		}
	}

	.ir-in {
		background-size: contain;
		margin-top: 12px;
	}
}

.contact-field {
	.icon-text {
		padding-left: 10px;
	}

	a {
		text-decoration: none !important;
		color: #696969 !important;
	}
}

#subheader.text-center.subheader, #subheader2.text-center.subheader {
	visibility: visible !important;
	position: absolute;
	background-color: transparent !important;
	bottom: 130px;
	color: #fff !important;
	font-weight: bold;
	font-size: 30px;
	line-height: 120%;
	@media screen and (max-width: 600px) {
		font-size: 25px;
		padding: 0 10px;
	}

	span:last-child {
		color: #F07C00;
	}

	.arrow_div .arrow {
		position: absolute;
		bottom: 100px;
		left: 10%;
		z-index: 200;
		display: none !important;
		@media screen and (max-width: 600px) {
			bottom: 50px;
		}
	}
}

#subheader2.text-center.subheader {
	bottom: 80px;
}

.tt-text, .et-text {
	color: #F07C00 !important;
	font-size: 16px;
	font-weight: 800;
	letter-spacing: 1px;
	text-shadow: none;
	padding: 20px 0 10px 0;
}

.et-text {
	color: #fff !important;
}

.padd-bottom {
	padding-bottom: 50px;
}

.form-check-login.new {
	width: 500px;
	padding-top: 10px;
	@media screen and (max-device-width: 600px) {
		width: 208px;
	}

	#user-name,
	#user-pass {
		background-color: #fff !important;
		font-family: Nunito;
		font-style: normal;
		font-weight: 300;
		font-size: 14px;
		height: 40px !important;
		line-height: 40px !important;
		color: #1f1f1f !important;
		padding: 9px 20px;
	}
}

label.user-name,
label.user-pass {
	position: absolute;
	top: -12px;
	left: 10px;
	z-index: 10;
	background-color: #fff;
	padding: 0 5px;
	color: #9C9E9F;
}

label.user-pass {
	left: 189px;
}

.loginButton {
	.submit-form.btn2 {
		height: 40px !important;
		line-height: 40px !important;
		border-radius: 5% !important;

		span {
			font-family: Nunito;
			font-style: normal;
			font-weight: 600 !important;
			font-size: 14px;
		}
	}
}
.loginBox:nth-child(2) {
	display: flex;
	@media screen and (max-width: 720px) {
		display: none;
	}
	&.mobile {
		@media screen and (max-width: 720px) {
			display: inline-block !important;
			position: absolute;
			top: 40px;
			right: 40px;
			background-color: #fff;
			width: 250px;
			height: 216px;
			border: 1px solid #ddd;
			border-radius: 3%;
			top: 49px;
			padding: 20px;
			dt label.user-pass {
				top: 42px;
				left: 10px;
			}
			.loginButton {
				padding-left: 0px;
				.submit-form.btn2 {
					width: 205px;
					text-align: center;
				}
			}
			.form-layout-2 .req:nth-of-type(2) {
				display: flex;
				flex-direction: column;
				input {
					width: 205px;
					height: 20px;
					margin-bottom: 15px;
				}
			}
		}
		@media screen and (max-width: 380px) {
			right: 20px;
		}
	}
}
.mobile-login-box {
	display: none;
	@media screen and (max-width: 720px) {
		box-sizing: border-box;
		font-family: "Nunito", sans-serif;
		display: inline-block !important;
		height: 40px;
		padding: 10px 0px 10px 25px;
		color: #424242;
		border: 1px solid #ddd;
		border-radius: 5%;
		position: absolute;
		top: 5px;
		right: 40px;
		img {
			padding-right: 9px;
			margin-top: -5px;
		}
		span:first-of-type {
			padding-right: 20px;
		}
		.arrow {
			border-left: 1px solid #ddd;
			padding: 10px 5px 10px 12px;
			transform: rotate(180deg);
		}
	}
	@media screen and (max-width: 380px) {
		right: 21px;
	}
}
.logo img {
	@media screen and (max-width: 720px) {
		width: 120px;
		height: 30px;
		padding-left: -20px;
		margin-left: -20px;
	}
}
.login_header_flex fieldset.form-login {
	@media screen and (max-device-width: 600px) {
		min-width: 208px !important;
	}
}
